class LifeVine {
    private apiUrl: string;

    private _account: LifeVineAccount;
    private _bookings: LifeVineBookings;
    private _clients: LifeVineClients;
    private _criteria: LifeVineCriteria;
    private _session: LifeVineSession;
    private _settings: LifeVineSettings;
    private _sitters: LifeVineSitters;
    private _users: LifeVineUsers;

    constructor(apiUrl) {
        this.apiUrl = apiUrl;

        this._account = new LifeVineAccount(this);
        this._bookings = new LifeVineBookings(this);
        this._clients = new LifeVineClients(this);
        this._criteria = new LifeVineCriteria(this);
        this._session = new LifeVineSession(this);
        this._settings = new LifeVineSettings(this);
        this._sitters = new LifeVineSitters(this);
        this._users = new LifeVineUsers(this);
    }

    public ajax(method: string, path: string, data: any = null) {
        let options: AjaxOptions = {
            method: method,
            dataType: 'json',
            statusCode: {
                403: this._session.getTimeoutStatusCode()
            }
        };

        if (this._session.getToken()) {
            options.headers = {'X-CSRF-TOKEN': this._session.getToken()};
        }

        if (data) {
            if ((method === 'PUT' || method === 'POST')) {
                options.contentType = 'application/json';
                options.data = JSON.stringify(data);
            } else {
                options.data = data;
            }
        }

        return jQuery.ajax(lifeVineApiUrl + path, options);
    }

    public account() {
        return this._account;
    }

    public bookings() {
        return this._bookings.pass('');
    }

    public clients() {
        return this._clients;
    }

    public criteria() {
        return this._criteria.pass('');
    }

    public session() {
        return this._session;
    }

    public settings() {
        return this._settings;
    }

    public sitters() {
        return this._sitters;
    }

    public users() {
        return this._users;
    }

    static runCallbacks(callbacks: Function[], scope: any, ...args: any[]) {
        for (let callback of callbacks) {
            callback.apply(scope, args);
        }
    }
}

interface AjaxOptions {
    method: string;
    dataType: string;
    statusCode;
    headers?: any;
    contentType?: string;
    data?: string;
}